import React from "react";
import {
  useFloating,
  useClick,
  useRole,
  useDismiss,
  useInteractions,
  FloatingPortal,
  offset
} from "@floating-ui/react";
import {
  faChevronDown,
  faCrown,
  faLockOpen
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "~/utils/cn";
import { useLocation, useNavigate } from "@remix-run/react";

export default function SubscriptionsDropdown() {
  const [open, setOpen] = React.useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: open => setOpen(open),
    middleware: [offset(10)],
    placement: "bottom",
    strategy: "absolute"
  });

  const click = useClick(context, {
    event: "click"
  });

  const role = useRole(context, { role: "menu" });
  const dismiss = useDismiss(context, { ancestorScroll: true });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    role,
    dismiss
  ]);

  const subscriptions_mode =
    location.pathname === "/threads/subscriptions"
      ? "subscribers-only"
      : "public";

  return (
    <React.Fragment>
      <div
        ref={reference}
        {...getReferenceProps}
        onClick={() => setOpen(current => !current)}
      >
        <button
          type="button"
          className={cn(
            "flex items-center gap-x-2 text-sm font-medium text-pri dark:text-pri-d",
            {
              "drop-shadow-glow text-c-subscriptions dark:text-c-subscriptions":
                subscriptions_mode === "subscribers-only"
            }
          )}
        >
          <FontAwesomeIcon
            icon={
              subscriptions_mode === "subscribers-only" ? faCrown : faLockOpen
            }
            size="sm"
            fixedWidth
            className="-mt-0.5"
          />
          <span>
            {subscriptions_mode === "subscribers-only"
              ? "Subscribers Only"
              : "Public"}
          </span>

          <FontAwesomeIcon
            icon={faChevronDown}
            size="sm"
            fixedWidth
            className={cn("transition-transform duration-150", {
              "-rotate-180": open
            })}
          />
        </button>
      </div>
      <FloatingPortal>
        <AnimatePresence>
          {open && (
            <motion.div
              initial={{ y: -6, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -6, opacity: 0 }}
              transition={{ duration: 0.15 }}
              className="flex flex-col justify-start items-start bg-pri dark:bg-pri-d border border-pri dark:border-pri-d rounded-xl p-1.5 text-sm z-[1000] overflow-hidden drop-shadow-md min-w-[200px] shadow-[0_0_12px_3px_rgb(255_255_255_/_17%)]"
              ref={floating}
              style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
              {...getFloatingProps()}
              tabIndex={-1}
            >
              <button
                type="button"
                className={cn(
                  "flex items-center w-full gap-x-2 py-3 px-3 text-pri/70 dark:text-pri-d/70 rounded-lg transition-colors duration-150 hover:bg-pri-d/10 dark:hover:bg-pri/10",
                  {
                    "text-pri dark:text-pri-d":
                      subscriptions_mode === "subscribers-only"
                  }
                )}
                onClick={() => navigate("/threads/subscriptions")}
              >
                <FontAwesomeIcon icon={faCrown} size="sm" fixedWidth />
                Subscribers Only
              </button>
              <button
                type="button"
                className={cn(
                  "flex items-center w-full gap-x-2 py-3 px-3 text-pri/70 dark:text-pri-d/70 rounded-lg transition-colors duration-150 hover:bg-pri-d/10 dark:hover:bg-pri/10",
                  {
                    "text-pri dark:text-pri-d": subscriptions_mode === "public"
                  }
                )}
                onClick={() => navigate("/threads")}
              >
                <FontAwesomeIcon icon={faLockOpen} size="sm" fixedWidth />
                Public
              </button>
            </motion.div>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </React.Fragment>
  );
}

